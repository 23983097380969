<template>
  <div>
    <span class="text-value-lg d-block mb-2">
      {{ title }}
    </span>
    <b-form-select @input="loadSelectedSite" v-model="selectedSite">
      <b-form-select-option
          v-for="site in sites"
          :key="site.id"
          :value="site.id"
      >
        {{ site.name }}
      </b-form-select-option>
    </b-form-select>

    <div class="mt-3">
      <div class="row" v-if="!loading">
        <div class="col-12">
          <div class="row mt-4">
            <div class="col-12 col-xl-6 col-xxl-3">
              <ReportWidget
                  type="branches"
                  icon="fa-building"
                  :title="brancheTitle"
                  :widget="selected.totalAmountSitesWidget"
                  :selected="linkData(selectedSite, 'site')"
              >
                <template #chartData>
                  <Status :site="linkData(selectedSite, 'site')" :title="statusSitesTitle" :widget="selected.siteStatusWidget" />
                </template>
              </ReportWidget>
            </div>
            <div class="col-12 col-xl-6 col-xxl-3" v-for="objectType in objectReportWidgets" :key="objectType.id">
              <ReportWidget
                  :type="objectType.type"
                  :icon="objectType.icon"
                  :title="objectType.title"
                  :widget="objectType.widget"
                  :selected="objectType.selected"
              />
            </div>
          </div>
        </div>
        <div class="col-12 col-sm-12">
          <ObjectConditionMeasurement
              :title="objectsCategoriesTitle"
              :object-measurement="selected.physicalObjectsConditionMeasurementWidget"
              :no-data-label="noDataLabel"
              :no-data-link="noDataLink"
          />
        </div>
        <div class="col-12 col-sm-12">
          <ObjectAge
              :title="objectsAgeTitle"
              :physical-objects="selected.objectsAgeWidget"
              :no-data-label="noDataLabel"
          />
        </div>
      </div>
      <div v-else class="w-100 text-center mt-3">
        <b-spinner variant="primary" />
      </div>
    </div>

  </div>
</template>

<script>
import {BFormSelect, BFormSelectOption, BSpinner} from "bootstrap-vue";
import DataCheckMixin from "./DataCheckMixin";
import ReportWidget from "./Widgets/ReportWidget";
import Status from './Charts/Status'
import ObjectConditionMeasurement from './Charts/ObjectConditionMeasurement'
import ObjectAge from './Charts/ObjectAge'

export default {
  name: 'Dashboard',
  mixins: [DataCheckMixin],
  props: [
      'title',
      'api-url',
      'sites',
      'statusSitesTitle',
      'objectsTitle',
      'objectsApprovedTitle',
      'objectsRejectedTitle',
      'objectsCategoriesTitle',
      'objectsAgeTitle',
      'noDataLabel',
      'noDataLink',
  ],
  components: {
    BFormSelect,
    BFormSelectOption,
    BSpinner,
    ReportWidget,
    Status,
    ObjectConditionMeasurement,
    ObjectAge
  },
  data() {
    return {
      loading: true,
      selectedSite: this.sites[0].id,
      physicalObjects: [],
      selected: {
        siteStatusWidget: {},
        physicalObjectsWidget: {},
        physicalObjectsRejectedWidget: {},
        physicalObjectsApprovedWidget: {},
        physicalObjectsConditionMeasurementWidget: {},
        objectsAgeWidget: {},
        totalAmountSitesWidget: {}
      },
      objectReportWidgets: [],
      brancheTitle: 'Alle vestigingen'
    }
  },
  mounted () {
    this.$nextTick( async () => {
      await this.loadAllData()
    })
  },
  methods: {
    setBrancheTitle (site) {
      for (let i = 0, j = this.sites.length; i < j; i++) {
        if (this.sites[i].id === site) {
          this.brancheTitle = this.sites[i].name
        }
      }
    },
    setDashboardData (selected) {
      this.objectReportWidgets = []
      if (selected) {
        this.objectReportWidgets.push(
            {
              id: 1,
              type: 'objects',
              icon: 'fa-plug',
              title: this.objectsTitle,
              widget: selected.physicalObjectsWidget,
              selected: this.linkData(selected.physicalObjectsWidget, 'physical-object', '')
            },
            {
              id: 2,
              type: 'objects-approved',
              icon: 'fa-plug',
              title: this.objectsApprovedTitle,
              widget: selected.physicalObjectsApprovedWidget,
              selected: this.linkData(selected.physicalObjectsApprovedWidget, 'physical-object', 'approved')
            },
            {
              id: 3,
              type: 'objects-rejected',
              icon: 'fa-plug',
              title: this.objectsRejectedTitle,
              widget: selected.physicalObjectsRejectedWidget,
              selected: this.linkData(selected.physicalObjectsRejectedWidget, 'physical-object', 'rejected')
            }
        )
      }
      return []
    },
    linkData (selected, category, status) {
      if (selected !== '') {
        for (let i = 0, j = this.sites.length; i < j; i++) {
          if (this.sites[i].id === selected) {
            return {
              category,
              status,
              item: this.sites[i]
            }
          }
        }
      }

      return {
        category,
        status
      }
    },
    async loadAllData () {
      try {
        const res = await fetch(this.apiUrl)
        this.selected = await res.json()
        this.setDashboardData(this.selected)
      } catch (e) {
        this.loading = false
      } finally {
        this.loading = false
      }
    },
    async loadSelectedSite (site) {
      this.loading = true
      try {
        const res = await fetch(`${this.apiUrl}?selectedSite=${site}`);
        this.selected = await res.json()
        this.setDashboardData(this.selected)
        this.setBrancheTitle(site)
      } catch {}

      this.loading = false
    }
  }
}
</script>
