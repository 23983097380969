$(document).ready(function() {
    $('.openingDays button.add-btn').on('click', addOpeningHours);
    $('.openingDays button.remove-btn').on('click', removeOpeningHours);
    $('.entity-table-select-all').on('change', handleSelectAll);

    // https://stackoverflow.com/questions/65330180/filename-not-shown-in-symfony-5-2-in-form
    $('.custom-file-input').on('change', showInputFilename);

    $('select#user_type').on('change', function(event) {
        handleChangeUserType($(event.currentTarget));
    });

    handleChangeUserType($('select#user_type'));

    $('.user-rights-checkboxes input[type=checkbox]').on('change', changeUserRights);

    // TOGGLE DAYS
    const days = $('#site_openingHours > fieldset');

    days.each(function() {
        const day = $(this);
        if(day.find('fieldset.form-group').length) {
            day.addClass('is-active');
            $('.open-days li').eq(day.index()).addClass('is-active');
        }
    });

    if(days) {
        $('.open-days li a ').on('click', function(e) {
            const parentLi = $(this).parent();

            if(parentLi.hasClass('is-active')) {
                parentLi.removeClass('is-active');
                days.eq(parentLi.index()).find('fieldset.form-group').remove();
                days.eq(parentLi.index()).removeClass('is-active');
            } else {
                parentLi.addClass('is-active');
                days.eq(parentLi.index()).addClass('is-active');
            }
            e.preventDefault();
        });
    }

    // COPY TIMES
    $('.copy-times').on('click', function(e) {
        $('#site_openingHours > fieldset:first-child fieldset.form-group').each(function() {
            const times = $(this);
            const openingTime = times.find('div.form-group:nth-child(1) input').val();
            const closingTime = times.find('div.form-group:nth-child(2) input').val();

            days.each(function() {
                const day = $(this);
                day.find('fieldset.form-group').eq(times.index()).find('div.form-group:nth-child(1) input').val(openingTime);
                day.find('fieldset.form-group').eq(times.index()).find('div.form-group:nth-child(2) input').val(closingTime);
            });
        });
        e.preventDefault();
    });
});

function addOpeningHours(event) {
    let field = $(event.currentTarget).closest('div.form-group').children('div:first');
    let amountOfChildren = field.children().length;

    if (amountOfChildren === 2) {
        return;
    }

    let prototype = field.data('prototype').replace(/__name__/g, amountOfChildren);
    field.append(prototype);

    event.preventDefault();
}

function removeOpeningHours(event) {
    let field = $(event.currentTarget).closest('div.form-group').children('div:first');
    if (field.children().length === 0) {
        return;
    }

    field.children().last().remove();

    event.preventDefault();
}

function handleSelectAll(event) {
    const $selectAllCheckbox = $(event.target);
    const isSelectAllChecked = $selectAllCheckbox.is(':checked');

    $selectAllCheckbox.closest('table').find('tbody input:checkbox').prop('checked', isSelectAllChecked);
}

function handleChangeUserType(selectElement) {
    let shouldShow = selectElement.val() === 'maintainer';
    let parentDiv = selectElement.closest('.form-group');
    let sitesTable = parentDiv.closest('form').find('div.choice-table').parent('.form-group');

    if (shouldShow) {
        sitesTable.show();
    } else {
        sitesTable.hide();
    }
}

function showInputFilename(event) {
    let inputFile = event.currentTarget;
    $(inputFile).parent().find('.custom-file-label').html(inputFile.files[0].name);
}

function changeUserRights(event) {
    const checkbox = $(event.currentTarget);
    const right = checkbox.val();
    const container = $(checkbox.closest('.user-rights-checkboxes'));

    let selectedRights = 0;
    container.find('input[type=checkbox]:checked').each(function (index, element) {
        if ((selectedRights & $(element).val()) != $(element).val()) {
            selectedRights = selectedRights + parseInt($(element).val());
        }
    });

    if ((selectedRights & right) == right && !checkbox.is(':checked')) {
        selectedRights = selectedRights - right;
    }

    container.find('input[type=checkbox]').each(function (index, element) {
        element = $(element);
        element.prop('checked', (selectedRights & element.val()) == element.val());
    });
}
