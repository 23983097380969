import Quagga from '@ericblade/quagga2';
const mobile = require('is-mobile');
// Naive implementation of an event emitter, good enough for this use case
class EventEmitter {
    constructor() {
        this._events = {};
        this._onceKey = 'ONLY_ONCE_PLEASE';
    }

    on(eventName, handler) {
        if(!this._events[eventName]) {
            this._events[eventName] = [];
        }

        this._events[eventName].push(handler);

        return this;
    }

    off(eventName, handler = null) {
        if(!handler) {
            if(this._events[eventName]) {
                delete this._events[eventName];
            }

            return this;
        }

        if(this._events[eventName]) {
            this._events[eventName] = this._events[eventName].filter(boundHandler => {
                return boundHandler !== handler;
            });
        }

        return this;
    }

    emit(eventName) {
        const args = Array.prototype.slice.call(arguments);

        args.shift();

        if(this._events[eventName]) {
            this._events[eventName].forEach(handler => {
                handler.apply(null, args);
            });
        }

        if(this._events[eventName + this._onceKey]) {
            this._events[eventName + this._onceKey].forEach(handler => {
                handler.apply(null, args);
            });

            delete this._events[eventName + this._onceKey];
        }

        return this;
    }

    once(eventName, handler) {
        if(!this._events[eventName + this._onceKey]) {
            this._events[eventName + this._onceKey] = [];
        }

        this._events[eventName + this._onceKey].push(handler);

        return this;
    }
}

class QuaggaDetector extends EventEmitter {
    constructor(timeWindowMS = 3000) {
        super();
        if (
            !Number.isInteger(timeWindowMS) ||
            !timeWindowMS > 0
        ) {
            throw new Error('timeWindowMS must be a valid integer');
        }

        this.timeWindowMS = timeWindowMS;
        this.codeMap = {};
        this.totalDetected = 0;
        this.started = null;
        this.endListener = null;
        this.paused = false;
    }

    detect(data) {
        if (this.paused) {
            return false;
        }

        const code = data.codeResult.code;

        if (!this.started) {
            this.start();
        }

        if (!this.codeMap[code]) {
            this.codeMap[code] = {
                timesFound: 0,
                confidencePercentage: null
            };
        }

        this.codeMap[code].timesFound++;
        this.totalDetected++;
        this.emit('detect', code);
    }

    start() {
        this.started = new Date().getTime();

        this.emit('start');

        this.endListener = setTimeout(() => {
            this.end();
        }, this.timeWindowMS);
    }

    reset() {
        this.codeMap = {};
        this.totalDetected = 0;
        this.started = null;
        clearTimeout(this.endListener);
    }

    end() {
        let highest = -Infinity;
        let highestCode = null;

        for (let code in this.codeMap) {
            let codeCount = this.codeMap[code].timesFound;

            this.codeMap[code].confidencePercentage = Math.round(
                (this.codeMap[code].timesFound / this.totalDetected) * 100
            );

            if (codeCount > highest) {
                highest = codeCount;
                highestCode = code;
            }
        }

        let map = JSON.parse(JSON.stringify(this.codeMap));
        let result = {
            code: highestCode,
            confidencePercentage: this.codeMap[highestCode].confidencePercentage,
            codes: map
        };

        this.emit('end', result);

        this.setPaused(true);
        this.codeMap = {};
        this.totalDetected = 0;
        this.started = null;

        setTimeout(() => {
            this.setPaused(false);
        }, 1500);
    }

    setPaused(paused) {
        this.paused = paused;
    }
}

$(document).ready(function() {
    if (!mobile({tablet: true, featureDetect: true})) {
        $('div.scanner').remove();
        return;
    }

    if (!navigator.mediaDevices || typeof navigator.mediaDevices.getUserMedia !== 'function') {
        $('div.scanner').remove();
        return;
    }

    // Constant settings
    const height = 250;
    const width = 600;
    const area = 20;

    // Elements
    const target = document.querySelector('#barcodeScanner');
    const searchBarcodeUrl = target.getAttribute('data-search-barcode');
    const formgroup = target.parentElement;
    const input = $('#barcode');

    Quagga.init({
        locate: false,
        inputStream: {
            name: "Live",
            type: "LiveStream",
            constraints: {
                width: height,
                height: width,
                facingMode: "environment"
            },
            target: target,
            area: { // defines rectangle of the detection/localization area
                top: `${area}%`,    // top offset
                right: `${area}%`,  // right offset
                left: `${area}%`,   // left offset
                bottom: `${area}%`  // bottom offset
            },
        },
        decoder: {
            readers: [
                "ean_reader"
            ],
        },
        multiple: false
    }, function(err) {
        if (err) {
            const errorElMessage = document.querySelector('.error-message-video-stream');
            errorElMessage.classList.remove('d-none');
            Quagga.pause();
        }
    });

    const detector = new QuaggaDetector(2500);

    Quagga.onDetected(function (data) {
        detector.detect(data);
    });

    detector.on('start', () => {
        document.querySelector('#barcode-target-overlay').style.borderColor = 'orange';
    });

    let timer = null;

    detector.on('end', (result) => {
        clearTimeout(timer);
        $.ajax({
            url: searchBarcodeUrl,
            data: {barcode: result.code},
            success: (data) => {
                if (data.exists === true) {
                    document.querySelector('#barcode-target-overlay').style.borderColor = 'green';
                    input.val(result.code);
                    input.closest('form').submit();
                } else {
                    document.querySelector('#barcode-target-overlay').style.borderColor = 'red';
                    if (process.env.NODE_ENV !== 'production') {
                        input.val(result.code);
                    }
                    timer = setTimeout(() => {
                        if (document.querySelector('#barcode-target-overlay').style.borderColor === 'red') {
                            document.querySelector('#barcode-target-overlay').style.borderColor = 'white';
                        }
                    }, 2000);
                }
            },
            dataType: 'json'
        });
    });

    function toggleScanner() {
        const $target = $(target);

        $target.toggle();
        if(!$target.data('toggled')) {
            // Using 'yes' 'no' here since using 0/1 or true/false would mess with the condition above
            $target.data('toggled', 'yes');
        }

        const toggled = $target.data('toggled');

        if(toggled !== 'yes') {
            // Remove the targeting overlay
            document.querySelector('#barcode-target-overlay').remove();

            // Reset the target styling
            target.style.scale = `scale(1)`;
            target.style.width = 'inherit';
            target.style.height = 'inherit';

            Quagga.pause();

            $target.data('toggled', 'yes');

            return;
        }

        $target.data('toggled', 'no');

        // Calculate a scalefactor based on the width of the formgroup element
        // so we can use it to scale the video element with CSS transform scale
        // we do this so we can preserve the original resolution of the video stream
        // since the higher resolution is required for the scanner to properly function
        // but we don't want to display it at that size.
        //
        // Resizing the element itself will also change the resolution of the underlying video
        // stream, therefore we use this transform scaling method.
        const scaleFactor = (formgroup.clientWidth / width).toPrecision(2);

        // We set the origin to 0,0 so scaling happens towards the origin point (top left)
        target.style.transformOrigin = '0 0';
        target.style.transform = `scale(${scaleFactor})`;

        const rect = target.getBoundingClientRect();

        // Explicitly reset the styles so we can reclaim the whitespace leftover from the scale operation
        // this whitespace is otherwise left around the scaled element at the size of the element before the scale
        // occurred.
        target.style.width = rect.width;
        target.style.height = rect.height;

        const videoRect = target.querySelector('video').getBoundingClientRect();

        // Add an overlay
        const div = document.createElement('div');

        const vertical = videoRect.height * (area / 100);
        const horizontal = videoRect.width * (area / 100);

        div.style.width = (videoRect.width - (horizontal * 2)) + 'px';
        div.style.height = (videoRect.height - (vertical * 2)) + 'px';
        div.style.top = (videoRect.top + vertical) + 'px';
        div.style.left = (videoRect.left + horizontal) + 'px';

        // Currently setting CSS styles in JS here, this is generally not the way to go
        // but if we move this to a CSS class it would suggest that you can mess with the width/height/top/left
        // aswell. Since that is not the case we define them here so people know the proper context when
        // working with these styles.
        div.id = 'barcode-target-overlay';
        div.style.position = 'absolute';
        div.style.borderStyle = 'solid';
        div.style.borderColor = 'white';
        div.style.boxSizing = 'border-box';
        div.style.borderWidth = '5px';
        div.style.opacity = 0.5;

        document.querySelector('body').appendChild(div);

        Quagga.start();
    }

    $('#scanBarcode').on('click', function (event) {
        event.preventDefault();

        toggleScanner();
    });
});
