var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "row mt-md-4" }, [
    _vm.objectMeasurement.error === ""
      ? _c("div", { staticClass: "col-12" }, [
          _c(
            "div",
            { staticClass: "row" },
            [
              _c("div", { staticClass: "col-12" }, [
                _c("span", { staticClass: "text-value-lg" }, [
                  _vm._v("\n            " + _vm._s(_vm.title) + "\n        "),
                ]),
              ]),
              _vm._v(" "),
              _vm._l(_vm.chartData, function (chart, index) {
                return _c(
                  "div",
                  { key: index, staticClass: "col-12 col-xl-6 mt-3" },
                  [
                    _c(
                      "div",
                      {
                        staticClass: "card bg-white",
                        class: {
                          "clickable-card":
                            !_vm.hasAmount[index] && chart.noDataLink,
                        },
                        on: {
                          click: function ($event) {
                            return _vm.navigate(index, chart.noDataLink)
                          },
                        },
                      },
                      [
                        _c(
                          "div",
                          { staticClass: "card-body d-flex flex-column" },
                          [
                            _c("span", { staticClass: "text-value-lg" }, [
                              _vm._v(
                                "\n              " +
                                  _vm._s(chart.title) +
                                  "\n            "
                              ),
                            ]),
                            _vm._v(" "),
                            _vm.hasAmount[index]
                              ? _c("CChartDoughnut", {
                                  staticStyle: { height: "200px" },
                                  attrs: {
                                    labels: chart.labels,
                                    datasets: chart.dataSet,
                                    options: _vm.options,
                                  },
                                })
                              : _c("span", {
                                  domProps: {
                                    innerHTML: _vm._s(chart.noDataLabel),
                                  },
                                }),
                          ],
                          1
                        ),
                      ]
                    ),
                  ]
                )
              }),
            ],
            2
          ),
        ])
      : _c("div", { staticClass: "col-12" }, [
          _c("div", { staticClass: "card bg-white" }, [
            _c("div", { staticClass: "card-body d-flex flex-column" }, [
              _c("span", [
                _vm._v(
                  "\n          " +
                    _vm._s(_vm.objectMeasurement.error) +
                    "\n        "
                ),
              ]),
            ]),
          ]),
        ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }