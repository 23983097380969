var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "row" }, [
    _c("div", { staticClass: "col-12 col-xl-12 mt-3" }, [
      _c("div", { staticClass: "card bg-white" }, [
        _c("div", { staticClass: "card-body d-flex flex-column" }, [
          _c("span", { staticClass: "text-value-lg" }, [
            _vm._v("\n          " + _vm._s(_vm.title) + "\n        "),
          ]),
          _vm._v(" "),
          _vm.physicalObjects.error === ""
            ? _c(
                "div",
                [
                  _vm.hasLength(_vm.physicalObjects)
                    ? _c("CChartDoughnut", {
                        staticStyle: { height: "300px" },
                        attrs: {
                          labels: _vm.labels,
                          datasets: _vm.dataSet,
                          options: _vm.options,
                        },
                      })
                    : _c("span", [
                        _vm._v(
                          "\n              " +
                            _vm._s(_vm.noDataLabel) +
                            "\n            "
                        ),
                      ]),
                ],
                1
              )
            : _c("span", [
                _vm._v(
                  "\n          " +
                    _vm._s(_vm.physicalObjects.error) +
                    "\n        "
                ),
              ]),
        ]),
      ]),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }