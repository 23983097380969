<template>
  <div class="w-100">
    <div v-if="widget.error !== ''">
      {{ widget.error }}
    </div>
    <div v-else class="d-flex flex-column w-100">
      <div class="text-value-xl">
        {{ title }}
      </div>
      <CChartDoughnut
          style="height:200px;"
          :labels="labels"
          :datasets="dataSet"
          :options="options"
      />
    </div>
  </div>
</template>

<script>
import {CChartDoughnut} from '@coreui/vue-chartjs'
import DataCheckMixin from "../DataCheckMixin";

export default {
  mixins: [DataCheckMixin],
  components: {
    CChartDoughnut
  },
  props: {
    title: {
      type: String,
      required: true,
      default: ''
    },
    site: {
      type: Object,
      required: false,
      default: () => ({})
    },
    widget: {
      type: Object,
      required: true,
      default() {
        return {
          error: '',
          data: []
        }
      }
    }
  },
  data() {
    return {
      labels: [],
      dataSet: [{
        data: [],
        backgroundColor: [],
        hoverBackgroundColor: []
      }],
      currentSite: '',
      options: {
        maintainAspectRatio: false,
        responsive: true,
        legend: {
          position: 'left',
          onClick: function (event, legendItem) {
            const currentStatus = legendItem.text.trim().split('(')[0]

            if (!this.site.item) {
              return window.open(`/site/?status=${currentStatus}`, '_self')
            }

            const locationId = this.site.item.name.trim().split('(')[1].split(')')[0];
            window.open(`/site/?locationId=${locationId}&status=${currentStatus}`, '_self');

          }.bind(this),
          onHover: (event) => {
            event.target.style.cursor = 'pointer';
          },
          onLeave: (event) => {
            event.target.style.cursor = 'default';
          }
        }
      }
    }
  },
  watch: {
    widget: {
      deep: true,
      immediate: true,
      handler(widget) {
        if (!this.hasKeys(widget)) {
          return
        }
        const colorLabels = this.generateLabelColors(widget.data)
        this.labels = colorLabels.map((label) => `${label.status} (${label.amount})`)
        this.dataSet.forEach((set) => {
          set.data = widget.data.map((status) => status.amount)
          set.backgroundColor = colorLabels.map((label) => label.color)
        })
      }
    }
  },
  methods: {
    generateLabelColors(labels) {
      return labels.map(({ status, amount }) => {
        switch (status) {
          case 'ok':
            return {
              status,
              color: '#50af31',
              amount
            }
          case 'aandacht':
            return {
              status,
              color: '#ffed00',
              amount
            }
          case 'actie':
            return {
              status,
              color: '#e30613',
              amount
            }
          default:
            return {
              status,
              color: '#8a93a2',
              amount
            }
        }
      })
    }
  }
}
</script>
