var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "w-100" }, [
    _vm.widget.error !== ""
      ? _c("div", [_vm._v("\n    " + _vm._s(_vm.widget.error) + "\n  ")])
      : _c(
          "div",
          { staticClass: "d-flex flex-column w-100" },
          [
            _c("div", { staticClass: "text-value-xl" }, [
              _vm._v("\n      " + _vm._s(_vm.title) + "\n    "),
            ]),
            _vm._v(" "),
            _c("CChartDoughnut", {
              staticStyle: { height: "200px" },
              attrs: {
                labels: _vm.labels,
                datasets: _vm.dataSet,
                options: _vm.options,
              },
            }),
          ],
          1
        ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }