$(document).ready(function() {

    const $sideBar = $('#sidebar');
    const $showHideBtnElement = $('[data-toggle="sidebar-show"]');

    const divElement = document.createElement('div');
    divElement.classList.add('c-sidebar-backdrop')
    divElement.classList.add('c-fade')

    $showHideBtnElement.on('click', function () {

        if ($sideBar.hasClass('c-sidebar-show')) {
            $sideBar.removeClass('c-sidebar-show');
            return;
        }

        if ($(window).width() >= 1024) {
            if ($sideBar.hasClass('c-sidebar-lg-show')) {
                $sideBar.removeClass('c-sidebar-lg-show')
            } else {
                $sideBar.addClass('c-sidebar-lg-show')
            }
            return;
        }

        $('body').append(divElement)
        divElement.classList.add('c-show');
        $sideBar.addClass('c-sidebar-show');
    });

    function backDropHandler () {
        if ($sideBar.hasClass('c-sidebar-show')) {
            $sideBar.removeClass('c-sidebar-show');
            $('.c-sidebar-backdrop').remove();
        }
    }

    divElement.addEventListener('click', backDropHandler);
});
