const DataCheckMixin = {
    methods: {
        hasKeys (obj) {
            return Object.keys(obj).length !== 0
        },
        hasLength (obj) {
            if (this.hasKeys(obj)) {
                return obj.data.length !== 0
            }
            return false
        },
        getAmountFromLabel(legend) {
            const amount = legend.match(/(\d+)/g)
            return parseInt(amount[1], 10)
        }
    }
}

export default DataCheckMixin
