var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c("span", { staticClass: "text-value-lg d-block mb-2" }, [
        _vm._v("\n    " + _vm._s(_vm.title) + "\n  "),
      ]),
      _vm._v(" "),
      _c(
        "b-form-select",
        {
          on: { input: _vm.loadSelectedSite },
          model: {
            value: _vm.selectedSite,
            callback: function ($$v) {
              _vm.selectedSite = $$v
            },
            expression: "selectedSite",
          },
        },
        _vm._l(_vm.sites, function (site) {
          return _c(
            "b-form-select-option",
            { key: site.id, attrs: { value: site.id } },
            [_vm._v("\n      " + _vm._s(site.name) + "\n    ")]
          )
        }),
        1
      ),
      _vm._v(" "),
      _c("div", { staticClass: "mt-3" }, [
        !_vm.loading
          ? _c("div", { staticClass: "row" }, [
              _c("div", { staticClass: "col-12" }, [
                _c(
                  "div",
                  { staticClass: "row mt-4" },
                  [
                    _c(
                      "div",
                      { staticClass: "col-12 col-xl-6 col-xxl-3" },
                      [
                        _c("ReportWidget", {
                          attrs: {
                            type: "branches",
                            icon: "fa-building",
                            title: _vm.brancheTitle,
                            widget: _vm.selected.totalAmountSitesWidget,
                            selected: _vm.linkData(_vm.selectedSite, "site"),
                          },
                          scopedSlots: _vm._u(
                            [
                              {
                                key: "chartData",
                                fn: function () {
                                  return [
                                    _c("Status", {
                                      attrs: {
                                        site: _vm.linkData(
                                          _vm.selectedSite,
                                          "site"
                                        ),
                                        title: _vm.statusSitesTitle,
                                        widget: _vm.selected.siteStatusWidget,
                                      },
                                    }),
                                  ]
                                },
                                proxy: true,
                              },
                            ],
                            null,
                            false,
                            939550375
                          ),
                        }),
                      ],
                      1
                    ),
                    _vm._v(" "),
                    _vm._l(_vm.objectReportWidgets, function (objectType) {
                      return _c(
                        "div",
                        {
                          key: objectType.id,
                          staticClass: "col-12 col-xl-6 col-xxl-3",
                        },
                        [
                          _c("ReportWidget", {
                            attrs: {
                              type: objectType.type,
                              icon: objectType.icon,
                              title: objectType.title,
                              widget: objectType.widget,
                              selected: objectType.selected,
                            },
                          }),
                        ],
                        1
                      )
                    }),
                  ],
                  2
                ),
              ]),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "col-12 col-sm-12" },
                [
                  _c("ObjectConditionMeasurement", {
                    attrs: {
                      title: _vm.objectsCategoriesTitle,
                      "object-measurement":
                        _vm.selected.physicalObjectsConditionMeasurementWidget,
                      "no-data-label": _vm.noDataLabel,
                      "no-data-link": _vm.noDataLink,
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "col-12 col-sm-12" },
                [
                  _c("ObjectAge", {
                    attrs: {
                      title: _vm.objectsAgeTitle,
                      "physical-objects": _vm.selected.objectsAgeWidget,
                      "no-data-label": _vm.noDataLabel,
                    },
                  }),
                ],
                1
              ),
            ])
          : _c(
              "div",
              { staticClass: "w-100 text-center mt-3" },
              [_c("b-spinner", { attrs: { variant: "primary" } })],
              1
            ),
      ]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }