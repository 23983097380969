let searchUrl = '';
$(document).ready(function() {
    searchUrl = $('div.error-reporting-search-page .search-locations').data('url');
    $('div.error-reporting-search-page select#site').select2();
    $('div.error-reporting-search-page select#site').on('change', findLocationsBySite);
    $('.error-report-elements').on('click', toggleErrorReportOption);
});

function findLocationsBySite(event) {
    let select = $(event.currentTarget);
    let siteId = select.val();

    select.closest('div.row.select').nextAll('div.row.select').remove();

    if (siteId === '') {
        return;
    }

    $.ajax({
        url: searchUrl,
        data: {siteId: siteId},
        success: handleLocationSelect,
        dataType: 'json'
    });
}

function findLocationsByLocation(event) {
    let select = $(event.currentTarget);
    let locationNumber = parseInt(select.attr('name').split('location').pop(), 10) + 1;
    let locationId = select.val();

    select.closest('div.row.select').nextAll('div.row.select').remove();

    if (locationId === '') {
        return;
    }

    $.ajax({
        url: searchUrl,
        data: {locationId: locationId, locationNumber: locationNumber},
        success: handleLocationSelect,
        dataType: 'json'
    });
}

function handleLocationSelect(data) {
    if (data.hasLocations) {
        createLocationsSelect(data);
    } else {
        createPhysicalObjectSelect(data);
    }
}

function createLocationsSelect(data) {
    let searchPage = $('div.error-reporting-search-page div.search-locations');
    searchPage.find('div.row.select').last().after(data.template);
    let locationNumber = data.locationNumber;

    $('div.error-reporting-search-page select#location' + locationNumber).select2();
    $('div.error-reporting-search-page select#location' + locationNumber).on('change', findLocationsByLocation);
}

function createPhysicalObjectSelect(data) {
    let searchPage = $('div.error-reporting-search-page div.search-locations');
    searchPage.find('div.row.select').last().after(data.template);

    $('div.error-reporting-search-page select.physical-object').select2();
    $('div.error-reporting-search-page select.physical-object').on('change', function(event) {
        $('.search-button button').prop('disabled', $(event.currentTarget).val() === '');
    });
}

function toggleErrorReportOption() {
    if (this.checked) {
        return;
    }

    let children = $(this).parent('div.custom-control.custom-switch').find('input.error-report-option');
    children.each(function(index) {
        $(this).prop('checked', false)
    });
}
