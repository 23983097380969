<template>
  <div class="row">
    <div class="col-12 col-xl-12 mt-3">
      <div class="card bg-white">
        <div class="card-body d-flex flex-column">
          <span class="text-value-lg">
            {{ title }}
          </span>
          <div v-if="physicalObjects.error === ''">
            <CChartDoughnut
                v-if="hasLength(physicalObjects)"
                style="height:300px"
                :labels="labels"
                :datasets="dataSet"
                :options="options"
            />
              <span v-else>
                {{ noDataLabel }}
              </span>
          </div>
          <span v-else>
            {{ physicalObjects.error }}
          </span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import {CChartDoughnut} from '@coreui/vue-chartjs'
import chroma from "chroma-js";
import DataCheckMixin from "../DataCheckMixin";

export default {
  mixins: [DataCheckMixin],
  components: {
    CChartDoughnut
  },
  props: {
    noDataLabel: {
      type: String,
      required: true,
      default: ''
    },
    title: {
      type: String,
      required: true,
      default: ''
    },
    physicalObjects: {
      type: Object,
      required: true,
      default: () => ({
        error: '',
        data: null,
      })
    }
  },
  data() {
    return {
      labels: [],
      dataSet: [
        {
          data: [],
          backgroundColor: [],
          hoverBackgroundColor: []
        }
      ],
      options: {
        maintainAspectRatio: false,
        responsive: true,
        tooltips: {
          boxWidth: 600,
          callbacks: {
            footer: ((tooltipItems, data) => {
              let footer = [];
              this.physicalObjects.data[tooltipItems[0].index].categories.forEach(category => {
                footer.push(`${category.type} ${category.amount}`)
              });

              return footer.join('\n');
            })
          }
        },
        legend: {
          position: 'left',
          labels: {
            generateLabels: function (chart) {
              const labels = [];
              for (let i = 0; i < chart.data.datasets[0].data.length; i++) {
                const amount = chart.data.labels[i].match(/(\d+)/g)
                const fillStyle = parseInt(amount[1], 10) <= 0 ? '#FFFFFF' : chart.data.datasets[0].backgroundColor[i]
                const text = chart.data.labels[i]
                if (chart.data.labels[i]) {
                  labels.push({ text, fillStyle });
                }
              }
              return labels
            }
          },
          onClick: function (event, legendItem) {
            if (this.getAmountFromLabel(legendItem.text) <= 0) {
              return;
            }
            const year = legendItem.text.trim().split('-')[0]
            window.open(`/physical-object/?productionYear=${year}`, '_self')
          }.bind(this),
          onHover: function (event, legendItem) {
            if (this.getAmountFromLabel(legendItem.text)  <= 0) {
              return;
            }
            event.target.style.cursor = 'pointer';
          }.bind(this),
          onLeave: function (event, legendItem) {
            if (this.getAmountFromLabel(legendItem.text)  <= 0) {
              return;
            }
            event.target.style.cursor = 'default';
          }.bind(this)
        },
      }
    }
  },
  watch: {
    physicalObjects: {
      deep: true,
      immediate: true,
      handler(objects) {
        this.clearData();
        const amountArray = []
        const today = new Date()

        if (!this.hasKeys(objects)) {
          return
        }
        const colors = chroma.scale(['#50af31', '#c7d301', '#ffed00', '#fbb900', '#ec6707', '#e30613'])
                             .colors(objects.data.length);
        objects.data.forEach((age, index) => {
          const objectYear = today.getFullYear() - age.age;
          const objectTypeAndAmount = age.categories.map((item) => `${item.type} (${item.amount})`).join(' / ')
          this.labels.push(`${objectYear} - ${objectTypeAndAmount}`)
          amountArray.push(age.categories.reduce((pv, cv) => pv + cv.amount, 0));

          this.dataSet[0].backgroundColor.push(colors[index])
          this.dataSet[0].hoverBackgroundColor.push(colors[index])
        });

        this.dataSet.forEach((set) => {
          set.data = amountArray;
        });
      }
    }
  },
  methods: {
    clearData () {
      this.labels = []
      this.dataSet[0] = {
        data: [],
        backgroundColor: [],
        hoverBackgroundColor: []
      };
    }
  }
}
</script>
