<template>
  <div class="card bg-white" :class="getContrast('#fff')">
    <div class="card-body card-body d-flex flex-column align-items-start">
      <div v-if="typeOfData !== 'object'" class="w-100">
          <div v-if="widget.error === ''" class="w-100">
            <a :href="generateLink(selected)" :title="title" class="text-value-lg d-flex align-items-center w-100">
              <span v-if="title === 'Alle vestigingen'">
                {{ widget.data }}
              </span>
              <div :class="title === 'Alle vestigingen' ? 'ml-2' : ''" class="d-flex position-relative justify-content-between w-100">
                <span class="d-block mw-75 w-75 overflow-hidden text-truncate text-nowrap">{{ title }}</span>
                <i :class="`fas ${icon}`"></i>
              </div>
            </a>
          </div>
          <div v-else class="card-body card-body d-flex flex-column align-items-start">
            {{ widget.error }}
          </div>
      </div>
      <div v-else class="w-100">
        <a :href="createLinkAllObjects(selected)" :title="title" class="text-value-lg d-flex align-items-center w-100">
          <span>{{ totalAmount }}</span>
          <div class="ml-2 d-flex justify-content-between w-100">
            <span class="d-block">{{ title }}</span>
            <i :class="`fas ${icon}`"></i>
          </div>
        </a>
      </div>
      <slot v-if="title !== '' && widget.error === ''" name="chartData" />
    </div>
    <div v-if="typeOfData !== 'number'">
      <ul class="list-group" v-if="widget.error === ''">
        <li v-for="(obj, index) in widget.data" :key="index" :class="getContrast('#fff')" class="list-group-item list-group-item-action d-flex justify-content-between align-items-center">
          <a :href="createObjectLink(selected, obj)" :title="obj.title">
            {{ obj.title  }}
          </a>
          <span class="badge badge-primary badge-pill">
            {{ obj.amount }}
          </span>
        </li>
      </ul>
      <div v-else class="card-body card-body d-flex flex-column align-items-start">
        {{ widget.error }}
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "ObjectAmountWidget",
  props: {
    type: {
      type: String,
      required: true,
      default: () => ''
    },
    icon: {
      type: String,
      required: true,
      default: () => ''
    },
    title: {
      type: String,
      required: true,
      default: () => ''
    },
    selected: {
      type: Object,
      required: false,
      default: () => ({})
    },
    widget: {
      type: Object,
      required: true,
      default() {
        return {
          error: '',
          data: null
        }
      }
    }
  },
  computed: {
    typeOfData() {
      return typeof this.widget.data
    },
    totalAmount () {
      return this.widget.data.reduce((a, b) => a + b.amount, 0);
    }
  },
  methods: {
    constructURLQuery(category, obj, status) {
        if (status === '' || obj === '') {

          if (status !== '' && obj === '') {
            return `/${category}/?status=${status}`
          }

          if (status === '' && obj !== '') {
            return `/${category}/?objectGroup=${obj}`
          }

          return `/${category}`
        }

      return `/${category}/?objectGroup=${obj}&status=${status}`
    },
    createLinkAllObjects(selected){
      return this.constructURLQuery('physical-object', '', selected.status)
    },
    createObjectLink (selected, obj) {
      const { category, status } = selected;
      if (obj.amount <= 0) {
        return;
      }
      const { title } = obj;
      switch (title) {
        case 'Blusser':
          return this.constructURLQuery(category, 'extinguisher', status)
        case 'Haspel':
          return this.constructURLQuery(category, 'fire%20hose%20reel', status)
        case 'Noodverlichting':
          return this.constructURLQuery(category, 'emergency%20lighting', status)
        case 'Overig':
          return this.constructURLQuery(category, 'other', status)
        default:
          return this.constructURLQuery(category, '', '')
      }
    },
    generateLink (selected) {
      const { category, item } = selected
      if (!item) {
        return `/${category}/`;
      }
      const locationId = item.name.trim().split('(')[1].split(')')[0];

      return `/${category}/?locationId=${locationId}`;
    },
    getContrast (hexColor){
      if (hexColor.slice(0, 1) === '#') {
        hexColor = hexColor.slice(1);
      }

      if (hexColor.length === 3) {
        hexColor = hexColor.split('').map(function (hex) {
          return hex + hex;
        }).join('');
      }

      const r = parseInt(hexColor.substr(0,2),16);
      const g = parseInt(hexColor.substr(2,2),16);
      const b = parseInt(hexColor.substr(4,2),16);
      const yiq = ((r * 299) + (g * 587) + (b * 114)) / 1000;

      return (yiq >= 128) ? 'text-black' : 'text-white';
    }
  }
}
</script>
