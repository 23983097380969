import select2 from 'select2' // Important to make sure select2 is initialized
import DataTable from '@webmen/datatable-bundle/js/component/DataTable'
import Dashboard from "./component/Dashboard"
import 'bootstrap'
import '@fortawesome/fontawesome-pro/js/all'
import Vue from 'vue'
const $ = require('jquery')

$.fn.select2.defaults.set('theme', 'bootstrap');
$.fn.select2.defaults.set('width', null);

new Vue({
    el: '#app',
    components: {DataTable, Dashboard}
})
