var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "card bg-white", class: _vm.getContrast("#fff") },
    [
      _c(
        "div",
        {
          staticClass:
            "card-body card-body d-flex flex-column align-items-start",
        },
        [
          _vm.typeOfData !== "object"
            ? _c("div", { staticClass: "w-100" }, [
                _vm.widget.error === ""
                  ? _c("div", { staticClass: "w-100" }, [
                      _c(
                        "a",
                        {
                          staticClass:
                            "text-value-lg d-flex align-items-center w-100",
                          attrs: {
                            href: _vm.generateLink(_vm.selected),
                            title: _vm.title,
                          },
                        },
                        [
                          _vm.title === "Alle vestigingen"
                            ? _c("span", [
                                _vm._v(
                                  "\n              " +
                                    _vm._s(_vm.widget.data) +
                                    "\n            "
                                ),
                              ])
                            : _vm._e(),
                          _vm._v(" "),
                          _c(
                            "div",
                            {
                              staticClass:
                                "d-flex position-relative justify-content-between w-100",
                              class:
                                _vm.title === "Alle vestigingen" ? "ml-2" : "",
                            },
                            [
                              _c(
                                "span",
                                {
                                  staticClass:
                                    "d-block mw-75 w-75 overflow-hidden text-truncate text-nowrap",
                                },
                                [_vm._v(_vm._s(_vm.title))]
                              ),
                              _vm._v(" "),
                              _c("i", { class: `fas ${_vm.icon}` }),
                            ]
                          ),
                        ]
                      ),
                    ])
                  : _c(
                      "div",
                      {
                        staticClass:
                          "card-body card-body d-flex flex-column align-items-start",
                      },
                      [
                        _vm._v(
                          "\n          " +
                            _vm._s(_vm.widget.error) +
                            "\n        "
                        ),
                      ]
                    ),
              ])
            : _c("div", { staticClass: "w-100" }, [
                _c(
                  "a",
                  {
                    staticClass:
                      "text-value-lg d-flex align-items-center w-100",
                    attrs: {
                      href: _vm.createLinkAllObjects(_vm.selected),
                      title: _vm.title,
                    },
                  },
                  [
                    _c("span", [_vm._v(_vm._s(_vm.totalAmount))]),
                    _vm._v(" "),
                    _c(
                      "div",
                      {
                        staticClass:
                          "ml-2 d-flex justify-content-between w-100",
                      },
                      [
                        _c("span", { staticClass: "d-block" }, [
                          _vm._v(_vm._s(_vm.title)),
                        ]),
                        _vm._v(" "),
                        _c("i", { class: `fas ${_vm.icon}` }),
                      ]
                    ),
                  ]
                ),
              ]),
          _vm._v(" "),
          _vm.title !== "" && _vm.widget.error === ""
            ? _vm._t("chartData")
            : _vm._e(),
        ],
        2
      ),
      _vm._v(" "),
      _vm.typeOfData !== "number"
        ? _c("div", [
            _vm.widget.error === ""
              ? _c(
                  "ul",
                  { staticClass: "list-group" },
                  _vm._l(_vm.widget.data, function (obj, index) {
                    return _c(
                      "li",
                      {
                        key: index,
                        staticClass:
                          "list-group-item list-group-item-action d-flex justify-content-between align-items-center",
                        class: _vm.getContrast("#fff"),
                      },
                      [
                        _c(
                          "a",
                          {
                            attrs: {
                              href: _vm.createObjectLink(_vm.selected, obj),
                              title: obj.title,
                            },
                          },
                          [
                            _vm._v(
                              "\n          " + _vm._s(obj.title) + "\n        "
                            ),
                          ]
                        ),
                        _vm._v(" "),
                        _c(
                          "span",
                          { staticClass: "badge badge-primary badge-pill" },
                          [
                            _vm._v(
                              "\n          " + _vm._s(obj.amount) + "\n        "
                            ),
                          ]
                        ),
                      ]
                    )
                  }),
                  0
                )
              : _c(
                  "div",
                  {
                    staticClass:
                      "card-body card-body d-flex flex-column align-items-start",
                  },
                  [_vm._v("\n      " + _vm._s(_vm.widget.error) + "\n    ")]
                ),
          ])
        : _vm._e(),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }